//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import moment from "moment";
import {fetchCauseStep, fetchContractType} from "@/api/master";
import {Message} from "element-ui";
import {fetchMarketingCheckWorkDetail} from "@/api/checkWork";

export default {
  name: 'CheckWorkDetail',
  beforeRouteEnter(to, from, next) {
    next(vm => {
      vm.usersOpened = from.fullPath.includes('CheckWork')
    })
  },
  created() {
    document.title = this.$route.name + " : ONE Tracemoney"
  },
  data() {
    return {
      dataParam: {
        contractId: this.$route.params.id
      },
      contractDetail: {
        contract_no: '',
        images: []
      },
      loanType: ['ไฟแนนซ์', 'ที่ดิน', 'p_loan', 'โอนรถแล้ว'],
      status: [{value: 1, label: 'โอนเรียบร้อย'}, {value: 2, label: 'ขอเบิกก่อน'}],
      causeOption: ['','เอกสารเซ็นไม่ครบ', 'กุญเเจสำรอง', 'ใบตรวจสภาพ', 'คู่มือ'],
      isLoading: false,
      dialogImageUrl: '',
      dialogVisible: false,
      temp: {
        attachments: []
      },
      url: null,
      srcList: null,
      active: 1
    }
  },
  async mounted() {
    this.$store.state.loading = true
    try {
      await new Promise((resolve, reject) => {
        fetchContractType().then(response => {
          const check = response.header
          const data = response.body
          if (check.error === 'N') {
            this.loanType = data
            resolve()
          }
        }).catch(error => {
          this.errorMsg = error
          console.log(error)
          reject(error)
        })

        fetchCauseStep(this.causeParam).then(response => {
          const check = response.header
          const data = response.body
          if (check.error === 'N') {
            this.causeOption = data
            resolve()
          }
        }).catch(error => {
          this.errorMsg = error
          console.log(error)
          reject(error)
        })
      })
      await this.getContractDetail()
    } finally {
      this.$store.state.loading = false
    }
  },
  methods: {
    async getContractDetail() {
      const id = this.$route.params.id
      await fetchMarketingCheckWorkDetail({contractId: id}).then(response => {
        const check = response.header
        const data = response.body
        if (check.error === 'N') {
          this.contractDetail = data
          if (data.step_detail) {
            for (let item of data.step_detail) {
              if (item.status_id === 1) {
                item.icon = 'el-icon-close'
                item.type = 'danger'
              } else if (item.status_id === 2) {
                item.icon = 'el-icon-check'
                item.type = 'success'
              } else if (item.status_id === 4) {
                item.icon = 'el-icon-location'
                item.type = 'warning'
                item.appointmentDate = moment(item.appointment_date, 'YYYY-MM-DD').format('LL')
              } else {
                item.icon = 'el-icon-more'
                item.type = 'primary'
              }
            }
          }
          if (data.images) {
            this.srcList = []
            for (let image of data.images) {
              this.srcList.push(image.url)
            }
          }
        }
      }).catch(error => {
        Message({
          message: 'มีข้อผิดพลาด กรุณาติดต่อผู้ดูแลระบบ',
          type: 'error',
          duration: 5 * 1000,
        })
        this.errorMsg = error
        console.log(error)
      })
    },
    handlePictureCardPreview(file) {
      this.dialogImageUrl = file.url
      this.dialogVisible = true
    },
    goBack() {
      this.usersOpened ? this.$router.go(-1) : this.$router.push({path: '/marketing/CheckWork'})
    },
    next() {
      if (this.active++ > 2) this.active = 0;
    },
    test(step) {
      console.log('Step = ' + step);
    }
  },
}
