import request from '@/utils/request'
const baseURL = process.env.VUE_APP_BASE_API

export function fetchCheckWorkList(params) {
  return request({
    url: baseURL + '/checkWork/list',
    method: 'get',
    params: params
  })
}

export function fetchMarketingCheckWorkList(params) {
  return request({
    url: baseURL + '/marketing/list',
    method: 'get',
    params: params
  })
}

export function fetchMarketingCheckWorkDetail(params) {
  return request({
    url: baseURL + '/marketing/detail',
    method: 'get',
    params: params
  })
}
